import React from "react";
import { Link } from "gatsby";
import appData from "data/app.json";
import { useLocal } from "utils/useLocalContent";

import initFullNavbarMenu from "common/initFullNavbarMenu";


const links = [
  {
    name: "Home",
    link: "/"
  },
  {
    name: "About Us",
    link: "/about"
  },
  {
    name: "Service",
    link: "/services"
  },
  {
    name: "Contact",
    link: "/contact"
  }
];

const germanLinks = [
  {
    name: "Startseite",
    link: "/de/"
  },
  {
    name: "Über uns",
    link: "/de/about"
  },
  {
    name: "Dienstleistung",
    link: "/de/services"
  },
  {
    name: "Kontakt",
    link: "/de/contact"
  }
];

const NavbarFullMenu = ({ theme }) => {
  React.useEffect(() => {
    initFullNavbarMenu()
  }, []);

  const lang = useLocal();

  const currentLinks = lang === "de" ? germanLinks : links;


  return (
    <>
      <div id="navi" className={`topnav ${theme ? (theme === "light" ? "light" : "") : ""}`}>
        <div className="container-fluid">
          <div className="logo">
            <Link to={`/`}>
              {
                theme ? (
                  theme === "light" ? (
                    <img src={appData.darkLogo} alt="valvie" />
                  ) : (
                    <img src={appData.lightLogo} alt="valvie" />
                  )
                ) : (
                  <img src={appData.lightLogo} alt="valvie" />
                )
              }
            </Link>
          </div>
          <div className="menu-icon">
            <span className="icon">
              <i></i>
              <i></i>
            </span>
            <span className="text" data-splitting>
              <span className="menu-text word">Menu</span>
            </span>
          </div>
        </div>
      </div>

      <div className="hamenu">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-8">
              <div className="menu-links">
                <ul className="main-menu">
                  {currentLinks.map((link, index) => (
                    <li key={index}>
                      <div className="o-hidden">
                        <Link to={link.link} className="link">
                          <span className="nm">{`0${index + 1}.`}</span>{link.name}
                        </Link>
                      </div>
                    </li>
                  ))}
                  <li>
                    <div className="o-hidden">
                      <Link to="#" onClick={() => window.location.pathname = lang === "de" ? window.location.pathname.replace("/de", "") : `/de${window.location.pathname}`} className="link">
                        <span className="nm">{`0${currentLinks.length + 1}.`}</span>
                        {lang === "de" ? "EN" : "DE"}
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/*  <div className="col-lg-3 col-md-4">
              <div className="cont-info">
                <div className="item">
                  <h6>Phone :</h6>
                  <p>+03 762-2367-723</p>
                </div>
                <div className="item">
                  <h6>Address :</h6>
                  <p>
                    541 Melville Ave, Palo Alto, CA 94301, ask@ohio.colabr.io
                  </p>
                </div>
                <div className="item">
                  <h6>Email :</h6>
                  <p>
                    <a href="#0">Vie_website@gmail.com</a>
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarFullMenu;
