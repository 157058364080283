exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[lang]-about-index-jsx": () => import("./../../../src/pages/[lang]/about/index.jsx" /* webpackChunkName: "component---src-pages-[lang]-about-index-jsx" */),
  "component---src-pages-[lang]-contact-index-jsx": () => import("./../../../src/pages/[lang]/contact/index.jsx" /* webpackChunkName: "component---src-pages-[lang]-contact-index-jsx" */),
  "component---src-pages-[lang]-index-jsx": () => import("./../../../src/pages/[lang]/index.jsx" /* webpackChunkName: "component---src-pages-[lang]-index-jsx" */),
  "component---src-pages-[lang]-services-index-jsx": () => import("./../../../src/pages/[lang]/services/index.jsx" /* webpackChunkName: "component---src-pages-[lang]-services-index-jsx" */),
  "component---src-pages-[lang]-set-index-jsx": () => import("./../../../src/pages/[lang]/set/index.jsx" /* webpackChunkName: "component---src-pages-[lang]-set-index-jsx" */),
  "component---src-pages-[lang]-showcase-video-index-jsx": () => import("./../../../src/pages/[lang]/showcase/video/index.jsx" /* webpackChunkName: "component---src-pages-[lang]-showcase-video-index-jsx" */),
  "component---src-pages-[lang]-showcase-web-index-jsx": () => import("./../../../src/pages/[lang]/showcase/web/index.jsx" /* webpackChunkName: "component---src-pages-[lang]-showcase-web-index-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-set-index-jsx": () => import("./../../../src/pages/set/index.jsx" /* webpackChunkName: "component---src-pages-set-index-jsx" */),
  "component---src-pages-showcase-video-index-jsx": () => import("./../../../src/pages/showcase/video/index.jsx" /* webpackChunkName: "component---src-pages-showcase-video-index-jsx" */),
  "component---src-pages-showcase-web-index-jsx": () => import("./../../../src/pages/showcase/web/index.jsx" /* webpackChunkName: "component---src-pages-showcase-web-index-jsx" */)
}

