// src/utils/useLocalizedContent.js
// import { useParams } from "@reach/router";
//
import { useState, useEffect } from "react";

export const useLocalizedContent = (enContent, deContent) => {
    const { lang } = useParams() || {};

    console.log(lang);

    return lang === 'de' ? deContent : enContent;
};




export const useLocal = () => {
    const [firstSegment, setFirstSegment] = useState("");

    useEffect(() => {
        if (typeof window !== "undefined") {
            const path = window.location.pathname;
            const segments = path.split("/").filter(Boolean);
            setFirstSegment(segments[0] || "");
        }
    }, []);

    return firstSegment;
};